<template>
  <div class="bottom-view">
    <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2024264292号</a>
  </div>
</template>

<script setup lang="ts"> 

</script>

<style lang="scss" scoped>
.bottom-view{
    //决定定位在窗口的最下方
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 30px;
    background-color: #fff;
    border-top: 1px solid #ccc;
}
</style>